import React from 'react'
import Link from 'next/link'
import Logo from '@/components/blocks/logo'
import { ExternalLinkIcon } from 'lucide-react'

const menuSections = [
  {
    title: 'Product',
    items: [
      {
        label: 'Text-to-meme',
        link: '/text-to-meme-2',
      },
      {
        label: 'Template-to-meme',
        link: '/meme-generator',
      },
      {
        label: 'Search templates',
        link: '/search',
      },
      {
        label: 'Meme maker',
        link: '/meme-maker',
      },
      {
        label: 'Pricing',
        link: '/pricing',
      },
    ],
  },
  {
    title: 'Resources',
    items: [
      {
        label: 'Pitch Deck',
        link: '/pitch-deck',
      },
      {
        label: 'Privacy Policy',
        link: '/privacy-policy',
      },
      {
        label: 'Terms & Conditions',
        link: '/terms-and-conditions',
      },
    ],
  },
  {
    title: 'Use Cases',
    items: [
      {
        label: 'For Marketers',
        link: '/meme-marketing',
      },
      {
        label: 'For Educators',
        link: '/teaching-with-memes',
      },
      {
        label: 'For Influencers',
        link: '/influencer-memes',
      },
    ],
  },
  {
    title: 'Company',
    items: [
      {
        label: 'Give feedback',
        link: 'https://supermeme.canny.io/what-should-we-build',
        openInNewTab: true,
      },
      {
        label: 'Contact',
        link: '/contact',
      },
      {
        label: 'Team',
        link: '/team',
      },
    ],
  },
]

const Footer: React.FC = () => {
  return (
    <footer
      className='bg-gray-50 text-gray-700'
      aria-labelledby='footer-heading'
    >
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      <div className='mx-auto max-w-full px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='space-y-4'>
            <Logo />
            <p className='text-sm leading-6 text-gray-600'>
              Generate original memes powered by OpenAI{' '}
              {/* Hack to force load Anton font */}
            </p>
            <span style={{ fontFamily: 'Anton', opacity: 0.1 }}>.</span>
            <div>
              <a
                href='https://www.producthunt.com/posts/supermeme-ai?utm_source=badge-top-post-badge&amp;utm_medium=badge&amp;utm_souce=badge-supermeme-ai'
                target='_blank'
              >
                <img
                  src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=345517&amp;theme=dark&amp;period=daily'
                  alt='Supermeme.ai - AI powered meme generator | Product Hunt'
                  style={{ width: 250, height: 54 }}
                  width='250'
                  height='54'
                />
              </a>
            </div>
          </div>
          <div className='mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-3 xl:col-span-2 xl:mt-0'>
            {menuSections.map((m) => (
              <div key={`key_footer_section_${m.title}`} className='space-y-4'>
                <h3 className='font-bold'>{m.title}</h3>
                <div className='space-y-4'>
                  {m.items.map((item) => (
                    <Link
                      key={`key_footer_label_${item.label}`}
                      href={item.link}
                      legacyBehavior
                    >
                      <a
                        className='flex items-center justify-start'
                        target={item.openInNewTab ? '_blank' : '_self'}
                      >
                        {item.label}
                        {item.openInNewTab && (
                          <ExternalLinkIcon className='ml-1 h-3 w-3' />
                        )}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24'>
          <p className='text-xs leading-5 text-gray-500'>
            &copy; {new Date().getFullYear()} Supermeme.ai. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
